import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import Banner from '../../assets/img/banner_legal.png'; 

const Terms = () => {
  return (
    <Grid container spacing={2} >
      {/* Banner en la parte superior */}
      <Grid item xs={12}>
        <Box
          sx={{
            width: '100%',
            height: 'auto',
            overflow: 'hidden',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mb: '20px', 
          }}
        >
          <img
            src={Banner} 
            alt="Términos y Condiciones"
            style={{
              width: '100%', 
              height: 'auto', 
            }}
          />
        </Box>
      </Grid>

      {/* Cuadro de texto con el título centrado */}
      <Grid item xs={12}>
        <Box
          sx={{
            width: '85%',
            margin: '0 auto',
            padding: '20px',
          }}
        >
          <Typography
            variant="h4"
            align="center" 
            sx={{ fontSize: { xs: '30px', sm: '40px', md: '45px' }, fontFamily: 'Futura Medium Italic', color: '#0a3531',  mb: '40px' }}
          >
            Términos de Uso
          </Typography>

          <Typography variant="body1" sx={{ textAlign: 'justify', fontSize: { xs: '15px', sm: '20px', md: '20px' }, whiteSpace: 'pre-wrap', fontFamily: 'Futura Book Font', color: '#434342',  mb: '40px', mr: { xs: '30px', sm: '20px', md: '0px' } }}>
            ¿Quienes somos? 
            <br /><br />
            Bienvenido a nuestra plataforma de desarrollo de cursos en línea. Memotika, se enfoca en el desarrollo de cursos online para fortalecer las áreas críticas del conocimiento en el mundo digital donde los estudiantes se capacitarán en temáticas como tecnología educativa, emprendimiento y diseño mediante cursos y diplomados diseñados para fortalecer sus habilidades tecnológicas y digitales necesarias para sobresalir en sus carreras o proyectos personales. 
            <br /><br />
            Uso de la Plataforma 
            <br /><br />
            El acceso y uso de nuestra plataforma es para fines informativos y educativos. Los usuarios pueden navegar por las páginas principales, acceder a la información sobre cursos y utilizar las funcionalidades disponibles en el sitio web, como las páginas de acerca de y presentación de cursos. El uso indebido de la plataforma, como actividades que infrinjan estos términos, pueden conllevar la suspensión o cancelación del acceso. 
            <br /><br />
            Creación de Cuentas y Recopilación de Datos
            <br /><br />
            Al utilizar algunas funciones de la plataforma, como la adquisición de cursos o la solicitud de información, es posible que se te solicite crear una cuenta o proporcionar datos personales. Es tu responsabilidad proporcionar información veraz y actualizada. La administración de la plataforma se compromete a proteger tus datos en conformidad con nuestra Política de Privacidad. 
            <br /><br />
            Responsabilidades del Usuario 
            <br /><br />
            El usuario se compromete a utilizar la plataforma de manera adecuada y conforme a las leyes vigentes. Está prohibido el uso del sitio para fines ilegales, para la distribución de contenido ofensivo, fraudulento o que afecte la integridad del sitio web y de otros usuarios. Cualquier violación de estos términos puede dar lugar a la suspensión o cancelación de tu acceso a la plataforma. 
            <br /><br />
            Limitación de Responsabilidad
            <br /><br />
            La plataforma se ofrece tal como está, sin garantías de ningún tipo. No nos hacemos responsables de errores técnicos, interrupciones del servicio, pérdida de datos, u otros problemas que puedan surgir en el uso de la plataforma. No garantizamos que la plataforma estará disponible sin interrupciones ni que estará libre de errores. 
            <br /><br />
            Cursos Ofrecidos 
            <br /><br />
            Los cursos disponibles en nuestra plataforma se presentan con la información correspondiente en sus páginas de detalle, incluyendo la descripción, duración, objetivos y contenido. El costo de los cursos es opcional y puede estar sujeto a cambios sin previo aviso. La adquisición de los cursos estará sujeta a las condiciones específicas que se indiquen al momento de la compra. 
            <br /><br />
            Modificaciones en la Plataforma 
            <br /><br />
            Nos reservamos el derecho de modificar, suspender o discontinuar, temporal o permanentemente, cualquier parte de la plataforma, con o sin previo aviso. Estas modificaciones pueden incluir la estructura de la página principal, el listado de cursos, la sección "Acerca de", la funcionalidad de contacto, y otras características del sitio web. 
            <br /><br />
            Contacto 
            <br /><br />
            Si tienes alguna pregunta o inquietud sobre estos términos de uso, puedes contactarnos a través de nuestras fuentes de contacto 
            <br />Correo electrónico: memotika.cursosonline@gmail.com 
            <br />Celular: 3215956248.
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Terms; 