import React from 'react';
import Curso from "../../components/cursos/Curso"

function Cursos(){
    return(
        <div>
            <Curso/>    
        </div>
    )
}

export default Cursos