import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Box, Typography, Button } from '@mui/material';
import VerifiedIcon from '@mui/icons-material/Verified';
import axios from 'axios';
import Avion from '../../assets/img/avion.png'; 

const handleScrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth', 
  });
};

const Cursos = () => {
  const [courses, setCourses] = useState([]);
  const navigate = useNavigate();
  const [whatsappNumber, setWhatsappNumber] = useState('');

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get('https://memotika.com/courses/api/courses/');
        const uniqueCourses = Array.from(new Set(response.data.map(course => course.id)))
          .map(id => response.data.find(course => course.id === id));
        setCourses(uniqueCourses);
      } catch (error) {
        console.error('Error fetching courses:', error);
      }}
  
    const fetchWhatsappNumber = async () => {
      try {
        const whatsappResponse = await axios.get('https://memotika.com/main/api/whatsApp/');
        console.log('WhatsApp API response:', whatsappResponse.data);
    
        if (Array.isArray(whatsappResponse.data) && whatsappResponse.data.length > 0) {
          setWhatsappNumber(whatsappResponse.data[0].phone_number);
        } else {
          console.log('No WhatsApp number available');
        }
      } catch (error) {
        console.error('Error fetching WhatsApp number:', error);
      }
    };

    fetchData();
    fetchWhatsappNumber();
  }, []);

  const handleClick = () => {
    if (whatsappNumber) {
      window.open(`https://wa.me/${whatsappNumber}`, '_blank');
    } else {
      console.log('WhatsApp number not available');
    }
  };

  return (
    <Box
      sx={{
        mt:5,
        py: 8,
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        borderBottomLeftRadius: '40px', 
        borderBottomRightRadius: '40px', 
        overflow: 'hidden',
        paddingBottom:'0',
        padding:0,
      }}
    >
      <Container maxWidth={false}>
        {courses.map((course) => (
          <Box
            key={course.id}
            id={`course-${course.id}`}
            sx={{
              maxHeight:'2000px',
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              mb: {xs:2, md:14}, 
              backgroundColor: 'white',
              borderBottomLeftRadius: '40px',  
              borderBottomRightRadius: '40px',
              position: 'relative',
            }}
          >
            <Box
                sx={{
                  top:{xs:80,md:-40},
                  width:'97.6%',
                  position: 'absolute',
                  backgroundColor: '#b0d31d',
                  padding: { xs: '10px', md: '20px' },
                  borderBottomLeftRadius: '40px',
                  borderBottomRightRadius: '40px',
                  zIndex: 1,
                  display: 'flex',
                  textAlign: { xs: 'center', md: 'right' },
                  my: { xs: 3, md: 5 },
                }}
              >
                <Typography
                  variant="h6"
                  component="h3"
                  sx={{
                    fontSize: { xs: '1.3rem', md: '2rem' },
                    fontFamily: 'Futura Medium Italic',
                    color:'white',
                    mr: { xs: 0, md: 5 },
                    ml: { xs: 0, md: 40 },
                    lineHeight: 1.3,
                  }}
                >
                  {course.titulo}
                </Typography>
              </Box>

            <Box sx={{ position: 'relative', flex: 1 }}>
            
              <Box
                sx={{
                  left: { xs: '20%', md: '25%' }, 
                  position: 'absolute',
                  top: { xs: '-4%', md: '5%' },
                  width: { xs: '22vw', md: '10vw' }, 
                  height: { xs: 'auto', md: 'auto' }, 
                  backgroundColor: 'white',
                  padding: { xs: '15px', md: '20px' },
                  borderRadius: '40px',
                  zIndex: 2,
                  display: 'flex',
                  justifyContent: 'center', 
                  alignItems: 'center',
                  boxShadow: '0 4px 10px rgba(0, 0, 0, 0.5)',
                  my: { xs: 4, md: 0 },
                }}
              >

                <Typography
                  component="h3"
                  sx={{
                    fontFamily: 'Futura STD',
                    display: 'flex',
                    flexDirection: 'column', 
                    alignItems: 'center ',
                    fontSize: { xs: '1rem', md: '1.5rem' },
                  }}
                >

                  <Box
                    component="span"
                    sx={{
                      color:'#04373a',
                      fontFamily: 'Futura Medium Italic',
                      fontSize: { xs: '1.2rem', md: '1.5rem' },
                      marginTop: 1, 
                      marginBottom: -1,
                    }}
                  >
                    Nuestro
                  </Box>

                  <Box
                    component="span"
                    sx={{
                      color:'#1B8E5E',
                      fontFamily: 'Futura STDH',
                      fontSize: { xs: '1.5rem', md: '2.5rem' },
                    }}
                  >
                    CURSO
                  </Box>
                </Typography>

                <Box
                  component="img"
                  src={Avion}
                  alt="Imagen Avion"
                  sx={{
                    position: 'absolute',
                    top: { xs: '-5%', sm: '-3%', md: '-15%' }, 
                    right: { xs: '-5%', sm: '-5%', md: '-15%' }, 
                    width: { xs: '40%', sm: '40%', md: '48%' }, 
                    height: 'auto',
                    zIndex: 1,
                  }}
                />
              </Box>

              <Box
                component="div"
                sx={{
                  position: 'absolute',
                  top: { xs: '150px', md: '230px' }, 
                  left: {xs:'-45%',sm:'-30%', md:'-60%'},
                  transform: 'translateX(0)',
                  width: { xs: '250px', sm: '330px', md: '400px' },  
                  height: { xs: '250px', sm: '300px', md: '400px' }, 
                  borderTopRightRadius: '100%',  
                  borderBottomRightRadius: '100%',
                  backgroundColor: '#04373a',
                  zIndex: 1,
                  mt:{ xs: 15, md: 0 }
                }}
              />
              <Box
                component="img"
                src={course.imagen} 
                alt={course.titulo}
                sx={{
                  width: { xs: '250px', sm: '300px', md: '400px' },  
                  height: { xs: '250px', sm: '300px', md: '400px' },
                  borderRadius: '50%',
                  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.5)',
                  position: 'relative',
                  objectFit: 'cover',
                  top: { xs: '90px', md: '230px' }, 
                  left: { xs: '5%', sm:'30%', md: '0' }, 
                  zIndex: 2,
                  mt:{ xs: 23, md: 0 },
                  mb:9,
                }}
              />
            </Box>
            <Box
              sx={{
                flex: 2,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                textAlign: 'justify',
                pt: { xs: 2, sm: 30, md: 20 },
                px: { xs: 2, sm: 10, md: 6 },
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontFamily: 'Futura Book Font',
                  fontSize: { xs: '1rem', md: '1.2rem' },
                  mb: 2,
                  mt: { xs: 2, md: 7 },
                  mr: { xs: 3, md: 0 },
                }}
              >
                {course.resumen_descripcion}
              </Typography>
              <Typography 
                variant="body1" 
                sx={{ fontFamily: 'Futura Book Font', 
                fontSize: { xs: '1rem', md: '1.2rem' },
                mr: { xs: 8, md: 0 }, 
                }}>
                  {(course.resumen_caracteristicas || '').split('\r\n').map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </Typography>

              <Box 
                sx={{ 
                  display: 'flex', 
                  gap: 2, 
                  pt: { xs: 2, md: 0 }, 
                  mt: { md: '3%' }, 
                  mb: { md: '3%' }, 
                  flexDirection: { xs: 'column', md: 'row' }, 
                }}
              >
                <Box
                  component="span"
                  sx={{
                    fontFamily: 'Futura STDH',
                    fontSize: { xs: '1.2rem', md: '1.4rem' },
                    color: '#146d48',
                    padding: '2px 5px', 
                    mb: { xs: -2, md: 0 },
                  }}
                >
                  Duración: {course.duracion}
                </Box>

                <Box
                  component="span"
                  sx={{
                    fontFamily: 'Futura STDH',
                    fontSize: { xs: '1.2rem', md: '1.4rem' },
                    color: '#146d48',
                    padding: '2px 5px',
                    mb: { xs: -2, md: 0 },
                  }}
                >
                  Módulos: {`${course.modulos.length > 0 ? course.modulos.length : 'N/A'}`}
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center', 
                  }}
                >
                  {/* Mostrar el precio si está disponible */}
                  {/*{course.precio && (
                    <Box
                      sx={{
                        fontFamily: 'Futura STDH',
                        fontSize: { xs: '1.2rem', md: '1.4rem' },
                        color: '#146d48',
                        padding: '2px 5px',
                        fontWeight: 'bold',
                        textAlign: { xs: 'left', md: 'right' },
                      }}
                    >
                      Precio: ${course.precio}
                    </Box>
                  )}*/}
                  <Box
                    component="span"
                    sx={{
                      fontFamily: 'Futura STDH',
                      fontSize: { xs: '1.2rem', md: '1.4rem' },
                      color: '#146d48',
                      padding: '2px 5px',
                    }}
                  >
                    Incluye Certificado
                  </Box>
                  <VerifiedIcon 
                    sx={{ 
                      fontSize: { xs: '1.5rem', md: '1.8rem' }, 
                      color: '#5182e0', 
                      marginRight: '5px' 
                    }} 
                  />
                  
                </Box>
              </Box>             
              <Box 
                sx={{ 
                  display: 'flex', 
                  gap: { xs: 2, md: 4 }, 
                  pt: { xs: 3, md: 2 },
                  flexDirection: { xs: 'column', md: 'row' } 
                }}
              >
                {/* Botón de "Adquirir ahora" que cambia según el enlace disponible */}
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: '#104447',
                    '&:hover': { backgroundColor: '#b0d31d' },
                    fontSize: { xs: '1.2rem', md: '1.5rem' },
                    fontFamily: 'Futura STD',
                    textTransform: 'none',
                    borderRadius: '15px',
                  }}
                  onClick={() => {
                    if (course.link) {
                      window.location.href = course.link; 
                    } else {
                      handleClick(); 
                    }
                  }}
                >
                  Adquirir ahora!
                </Button>

                {/* Botón "Ver más" */}
                <Button
                  variant="outlined"
                  sx={{
                    borderColor: '#104447',
                    color: '#104447',
                    '&:hover': {
                      borderColor: '#b0d31d',
                      color: '#b0d31d',
                    },
                    fontSize: { xs: '1.2rem', md: '1.5rem' },
                    fontFamily: 'Futura STD',
                    textTransform: 'none',
                    borderRadius: '15px',
                  }}
                  onClick={() => {
                    handleScrollToTop(); 
                    navigate(`/cursos/${course.id}`); 
                  }}
                >
                  Conoce nuestro curso
                </Button>
              </Box>
            </Box>
          </Box>
        ))}
      </Container>
    </Box>
  );
};

export default Cursos;
