import React from 'react';
import { Box, Container, Typography, /*Button*/ } from '@mui/material'; 
import svgImage from '../../assets/img/problems.svg';
import Avion from '../../assets/img/avion.png'; 

/*const handleScrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      const navbarHeight = 95; 
        const sectionPosition = section.getBoundingClientRect().top + window.scrollY; 
        window.scrollTo({
            top: sectionPosition - navbarHeight, 
            behavior: 'smooth', 
        });
    }
  };*/

function Problems() {
    return (
      <Box
        sx={{
          py: 4,
          backgroundColor: '#f8f8f8',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          borderRadius: '40px',
          overflow: 'hidden',
          maxWidth: '1200px',
          margin: '0 auto',
          mb: '30px',
          boxShadow: '0px 6px 10px 0px rgba(0,0,0,0.14), 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
        }}
      >
        <Container>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignItems: 'flex-start',
              position: 'relative',
            }}
          >
            <Box sx={{ position: 'relative', flex: 1 }}>
              <Box
                sx={{
                  position: 'absolute', 
                  mt: { xs: 0, md: 2 },
                  left: { xs: 0, md: 20 },
                  width: { xs: '90%', sm:'100%', md: '190%' },
                  backgroundColor: '#104447',
                  color: 'white',
                  padding: { xs: '8px', md: '8px' },
                  borderTopRightRadius: '100px',  
                  borderBottomRightRadius: '100px',  
                  zIndex: { xs: 3, md: 1 },
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: { xs: 'center', md: 'flex-end' },
                  textAlign: { xs: 'center', md: 'right' },
                  wordWrap: 'break-word',
                  whiteSpace: 'normal',
                }}
              >
                <Typography
                  variant="h6"
                  component="h3"
                  sx={{
                    textAlign: 'center',
                    fontFamily: 'Futura STDH',
                    fontSize: { xs: '18px', sm:'20px', md: '30px' },
                    wordWrap: 'break-word',
                    mr: {xs: 3, md: 15},
                    whiteSpace: 'normal',
                  }}
                >
                  ¿Cuáles son los problemas más<br />
                  comunes de los docentes?
                </Typography>
                <Box
                  component="img"
                  src={Avion}
                  alt="Imagen Avion"
                  sx={{
                    position: 'absolute',
                    top: { xs: '25%', md: '15%' }, 
                    right: { xs: '-3%', md: '-2%' }, 
                    width: { xs: '20%', md: '13%' }, 
                    height: 'auto',
                    zIndex: 1,
                  }}
                />
              </Box>

              <Box
                component="img"
                src={svgImage} 
                alt="Problemas"
                sx={{
                  width: '100%',
                  height: 'auto',
                  position: 'relative',
                  zIndex: 2,
                  mt: { xs: '15%', md: 0 },
                }}
              />
            </Box>

            <Box
              sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                textAlign: 'left',
                pt: { xs: 2, md: 20 },
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontFamily: 'Futura Book Font',
                  fontSize: { xs: '16px', md: '1.4rem' },
                  mb: 1,
                  mr: { xs: 4.5, md: 1 },
                  lineHeight: { xs: 1.2, md: 1.5 },
                }}
              >
                1. ¡No tengo tiempo para nada!<br />
                2. Mis estudiantes están aburridos con clases monótonas.<br />
                3. La tecnología no es lo mío.<br />
                4. Mis materiales siempre se ven iguales.<br />
                5. No sé cómo hacer buenas presentaciones.<br />
                6. ¿Cómo pongo videos en mi clase?<br />
                7. Siempre dependo de plantillas viejas.<br />
                8. Me da miedo probar algo nuevo.<br />
                9. Mis clases necesitan un toque creativo.<br />
                10. La planeación de la clase me quita mucho tiempo.<br /><br />
                <span
                style={{ 
                    fontSize: '30px', 
                    fontFamily: 'Futura STDH', 
                    color: '#146d48', 
                }}>
                ¡Canva al rescate! Con este curso, transforma tu aula de clase.</span>
              </Typography>
              {/*<Box
                sx={{
                  display: 'flex', 
                  gap: { xs: 2, md: 4 }, 
                  pt: { xs: 3, md: 4 },
                  flexDirection: { xs: 'column', md: 'row' } 
                }}
              >
                <Button
                  variant="contained"
                  onClick={() => handleScrollToSection('seccion-cursos')}
                  sx={{
                    backgroundColor: '#104447',
                    '&:hover': { backgroundColor: '#b0d31d' },
                    fontSize: { xs: '1.2rem', md: '1.5rem' },
                    fontFamily: 'Futura STD',
                    textTransform: 'none',
                    borderRadius: '15px',
                  }}
                >
                  
                </Button>
              </Box>*/}
            </Box>
          </Box>
        </Container>
      </Box>
    );
  }
  
  export default Problems;


  
  
  
  
  
