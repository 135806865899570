import React, { useEffect, useState } from 'react';
import { Box, Container, Typography } from '@mui/material'; 
import axios from 'axios';
import Trofeo from '../../assets/img/Trofeo.svg'; 
import svgImage from '../../assets/img/fondo-tutor.svg'; 

function Tutor() {
  const [profesor, setProfesor] = useState(null);

  useEffect(() => {
      axios.get('https://memotika.com/courses/api/courses/') 
          .then(response => {
            if (response.data.length > 0) {
                setProfesor(response.data[0].profesor);
            } else {
                console.error('No se encontraron cursos.');
            }
        })
        .catch(error => {
            console.error('Error al obtener la información del profesor:', error);
        });
  }, []);

  if (!profesor) {
    return (
      <Typography 
        sx={{
          fontFamily: 'Futura STDH',
          fontSize: { xs: '14px', sm: '16px', md: '18px' },
          textAlign: 'center',
        }}
      >
        No hay docente disponible.
      </Typography>
    );
  }
  
    return (
      <Box
        sx={{
          py: 4,
          backgroundColor: '#f8f8f8',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          borderRadius: '40px',
          overflow: 'hidden',
          maxWidth: '1200px',
          margin: '0 auto',
          mt: '30px',
          mb: '30px',
          boxShadow: '0px 6px 10px 0px rgba(0,0,0,0.14), 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
        }}
      >
        <Container>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignItems: 'flex-start',
              position: 'relative',
            }}
          >
            <Box sx={{ position: 'relative', flex: 1 }}>
              <Box
                sx={{
                  position: 'absolute', 
                  mt: { xs: 0, md: 2 },
                  left: { xs: 0, md: 2 },
                  width: { xs: '90%', sm:'100%', md: '210%' },
                  backgroundColor: '#8a759c',
                  color: 'white',
                  padding: { xs: '15px', md: '20px' },
                  borderTopRightRadius: '100px',  
                  borderBottomRightRadius: '100px',  
                  zIndex: { xs: 3, md: 1 },
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: { xs: 'center', md: 'flex-end' },
                  textAlign: { xs: 'center', md: 'right' },
                  wordWrap: 'break-word',
                  whiteSpace: 'normal',
                }}
              >
                <Typography
                  variant="h6"
                  component="h3"
                  sx={{
                    textAlign: 'center',
                    fontFamily: 'Futura STDH',
                    fontSize: { xs: '30px', sm:'30px', md: '40px' },
                    wordWrap: 'break-word',
                    mr: {xs: 3, md: 17},
                    whiteSpace: 'normal',
                  }}
                >
                  Conoce a tu tutor
                </Typography>
                <Box
                  component="img"
                  src={Trofeo}
                  alt="Imagen Trofeo"
                  sx={{
                    position: 'absolute',
                    top: { xs: '-10%', md: '-30%' }, 
                    right: { xs: '-8%', md: '-6%' }, 
                    width: { xs: '27%', md: '16%' }, 
                    height: 'auto',
                    zIndex: 1,
                  }}
                />
              </Box>

              <Box
                sx={{ position: 'relative', flex: 0.6 }} 
              >
              <Box
                component="img"
                src={svgImage} 
                alt="Problemas"
                sx={{
                  width: '100%',
                  height: 'auto',
                  position: 'relative',
                  zIndex: 2,
                  mt: { xs: '15%', md: 0 },
                }}
              />
              <Box
                component="img"
                src={profesor.imagen} 
                alt="Imagen Tutor"
                sx={{
                  width: { xs: '70%', md: '70%' }, 
                  height: 'auto',  
                  maxHeight: '100%',  
                  objectFit: 'contain',  
                  position: 'absolute', 
                  bottom: '5.3px',  
                  left: '45%',  
                  transform: 'translateX(-50%)',  
                  zIndex: 3,  
                }}
              />
              </Box>
              
            </Box>

            <Box
              sx={{
                flex: 1.3,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                textAlign: 'left',
                pt: { xs: 2, md: 20 },
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontFamily: 'Futura Book Font',
                  fontSize: { xs: '16px', md: '1.2rem' },
                  mb: 1,
                  mr: { xs: 4.5, md: 7 },
                  lineHeight: { xs: 1.2, md: 1.5 },
                }}
              >
                <span
                style={{ 
                    fontSize: '23px', 
                    fontFamily: 'Futura STDH', 
                    color: '#0a3531', 
                }}>
                ¡Hola, soy<br />
                {profesor.nombre}
                </span> 
                <br /><br />
                {profesor.descripcion}
              </Typography>
            </Box>
          </Box>
        </Container>
      </Box>
    );
  }
  
  export default Tutor;


  
  
  
  
  
