import React from 'react';
import AppPoliticas from "../../components/navigation/AppPoliticas"

function Politicas(){
    return(
        <div>
            <AppPoliticas/>    
        </div>
    )
}

export default Politicas