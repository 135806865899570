import React, { useState, useEffect } from 'react';
import { Box, IconButton } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import tiktok_logo from '../../assets/tik-tok.png';
import axios from 'axios';

const Iconosredes = () => {
  const [isVisible, setIsVisible] = useState(true);
  const [whatsappNumber, setWhatsappNumber] = useState('');

  useEffect(() => {
    const handleScroll = () => {
      const footer = document.getElementById('footer'); 
      const footerPosition = footer?.getBoundingClientRect().top; 
      const screenHeight = window.innerHeight; 

      if (footerPosition && footerPosition <= screenHeight) {
        setIsVisible(false); 
      } else {
        setIsVisible(true); 
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll); 
    };
  }, []);

  useEffect(() => {
    const fetchWhatsappNumber = async () => {
      try {
        const response = await axios.get('https://memotika.com/main/api/whatsApp/');
        console.log('WhatsApp API response:', response.data);

        if (response.data.length > 0) {
          setWhatsappNumber(response.data[0].phone_number); 
        } else {
          console.log('No WhatsApp number available');
        }
      } catch (error) {
        console.error('Error fetching WhatsApp number:', error);
      }
    };

    fetchWhatsappNumber();
  }, []);

  const styles = {
    floatingIconsContainer: {
      position: 'fixed',
      top: '50%',
      right: '10px',
      transform: 'translateY(-50%)',
      display: 'flex',
      flexDirection: 'column',
      gap: '15px',
      zIndex: 1000,
    },
    iconButton: {
      backgroundColor: '#1B8E5E',
      color: 'white',
      '&:hover': {
        backgroundColor: '#157a4a',
      },
    },
    tiktokLogo: {
      width: '24px',
      height: '24px',
    },
  };

  return (
    isVisible && ( 
      <Box sx={styles.floatingIconsContainer}>
        <IconButton sx={styles.iconButton} aria-label="Facebook" href="https://www.facebook.com/profile.php?id=61566160027262" target="_blank">
          <FacebookIcon />
        </IconButton>
        <IconButton sx={styles.iconButton} aria-label="Instagram" href="https://www.instagram.com/memotika.cursosonline/" target="_blank">
          <InstagramIcon />
        </IconButton>
        <IconButton sx={styles.iconButton} aria-label="TikTok" href="https://www.tiktok.com/@memotika.cursosonline?_t=8q17ngK435Z&_r=1" target="_blank">
          <img src={tiktok_logo} alt="TikTok Logo" style={styles.tiktokLogo} />
        </IconButton>
        <IconButton sx={styles.iconButton} aria-label="WhatsApp" href={whatsappNumber ? `https://wa.me/${whatsappNumber}` : '#'} target="_blank">
          <WhatsAppIcon />
        </IconButton>
      </Box>
    )
  );
};

export default Iconosredes;

