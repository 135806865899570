import React from 'react';
import { Container, Box, Typography } from '@mui/material';
import image from '../../assets/img/imagen_info.png'; 
import decorImage from '../../assets/img/Marca Memótika-21.png'; 
import backgroundImage from '../../assets/img/Marca Memótika-15.png'; 

const Informacion = () => {
  return (
    <Box
      sx={{
        position: 'relative',
        py: 8,
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat', 
      }}
    >
      <Container sx={{ display: 'flex', alignItems: 'center', flexDirection: { xs: 'column', md: 'row' } }}>
        {/* Imagen y semicírculo verde */}
        <Box sx={{ position: 'relative', mr: { md: 4 }, mb: { xs: 0, md: 0 }, textAlign: 'center' }}>
          <Box
            component="div"
            sx={{
              position: 'absolute',
              top: {xs:'-25%', ms:'-20%'},
              left: '50%',
              transform: 'translateX(-50%)',
              width: { xs: '250px', sm: '300px', md: '400px' },  
              height: { xs: '125px', sm: '150px', md: '200px' }, 
              borderRadius: '0 0 200px 200px', 
              backgroundColor: '#04373a',  
              zIndex: -1,
            }}
          />
          <Box
            component="img"
            src={image} 
            alt="Informacion"
            sx={{
              top:{xs:-40},
              width: { xs: '250px', sm: '300px', md: '400px' },  
              height: { xs: '250px', sm: '300px', md: '400px' },
              borderRadius: '50%',
              objectFit: 'cover',
              position: 'relative',
              zIndex: 1,
              boxShadow: '10px 10px 20px rgba(0, 0, 0, 0.3)',
            }}
          />
        </Box>
        {/* Título y texto */}
        <Box sx={{ flex: 1, pr: { xs: 4, md: 0 }, display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center'}}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: { xs: 1, md: -2 }, ml: { xs: 0, md: 0 }, mt: { xs: -3, md: -5 } }}>
            <Box
              component="img"
              src={decorImage} 
              alt="Decoración"
              sx={{
                width: { xs: '90px', md: '180px' }, 
                height: { xs: '90px', md: '180px' },
                mr: { xs: -1, sm: 1, md: 1}, 
              }}    
            />
            <Typography variant="h5" component="h5" sx={{color:'#04373a', fontFamily: 'Futura STDH', fontSize: { xs: '40px', md: '60px' } }}>
              ¿Quiénes Somos?
            </Typography>
          </Box>
          <Typography variant="body1" sx={{ textAlign: 'justify', fontFamily: 'Futura Book Font', fontSize: { xs: '16px', sm: '20px', md: '24px' }, ml: { sm: '20px', md: '50px' }, mr: { xs: '15px'}  }}>
            En Memótika, transformamos la manera en que aprendes y compartes conocimiento. 
            Somos apasionados por la innovación educativa. 
            Con nuestros cursos, combinamos creatividad, innovación y estrategias de negocio
            para impulsar a nuestros estudiantes hacia el éxito. Creemos que el aprendizaje no tiene límites, 
            y en Memótika, te proporcionamos las herramientas necesarias para diseñar tu propio futuro y 
            potenciar tus habilidades.
          </Typography>
        </Box>
      </Container>
      <Box
        component="div"
        sx={{
          position: 'absolute',
          top: {xs:'87%', md:'68%'},
          right: '0',
          left:{xs:'50%', md:'65%'},
          transform: 'translateX(-50%)',
          width: { xs: '230px', md: '400px' },  
          height: { xs: '115px', md: '200px' }, 
          borderRadius: '200px 200px 0 0', 
          backgroundColor: 'rgba(181, 230, 29, 0.3)',
          zIndex: -1,
        }}
      />
    </Box>
  );
};

export default Informacion;

