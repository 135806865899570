import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import logo_2 from '../../assets/logo_2.png';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const pages = ['Inicio', 'Cursos', 'Conócenos'];

function Navbar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const navigate = useNavigate();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleScrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      const navbarHeight = 95; 
        const sectionPosition = section.getBoundingClientRect().top + window.scrollY; 
        window.scrollTo({
            top: sectionPosition - navbarHeight, 
            behavior: 'smooth', 
        });
    }
  };

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', 
    });
  };

  const handleNavigation = (page) => {
    switch (page) {
      case 'Cursos':
        if (window.location.pathname === '/') {
          handleScrollToSection('seccion-cursos');
        } else {
          navigate('/');
          setTimeout(() => handleScrollToSection('seccion-cursos'), 50);
        }
        break;
      case 'Contáctanos':
        if (window.location.pathname === '/') {
          handleScrollToSection('seccion-contacto');
        } else {
          navigate('/');
          setTimeout(() => handleScrollToSection('seccion-contacto'), 50);
        }
        break;
      case 'Inicio':
        navigate('/');
        setTimeout(() => handleScrollToSection('seccion-header'), 50);
        break;
      case 'Conócenos':
        navigate('/conocenos');
        setTimeout(() => handleScrollToTop(), 50);
        break;
      default:
        navigate(`/${page.toLowerCase().replace(/\s+/g, '')}`);
        break;
    }
    handleCloseNavMenu();
  };

  return (
    <AppBar position="sticky" sx={{ backgroundColor: 'white'}}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* Logo en todas las pantallas */}
          <Typography
            variant="h6"
            noWrap
            href="Home"
            sx={{
              mr: 2,
              display: 'flex',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'black',
              textDecoration: 'none',
            }}
          >
            <Link
              to="/"
              style={{ textDecoration: 'none' }}
              onClick={() => handleScrollToTop()}
            >
              <img
                src={logo_2}
                alt="Logo_memotika"
                style={{ width: '100%', maxWidth: '170px', height: 'auto', marginTop: '9px', marginBottom: '9px', marginRight: '30px' }}
              />
            </Link>
          </Typography>

          {/* Botones del menú para pantallas grandes */}
          <Box sx={{ display: { xs: 'none', md: 'flex' }, marginLeft: 'auto', marginRight: '30px' }}>
            {pages.map((page) => (
              <Button
                key={page}
                sx={{
                  textTransform: 'none',
                  fontSize: '16px',
                  fontFamily: 'Futura STD',
                  my: 2,
                  color: 'black',
                  mx: 2,
                  '&:hover': { color: '#1B8E5E', backgroundColor: 'transparent' }
                }}
                onClick={() => handleNavigation(page)}
              >
                {page}
              </Button>
            ))}
          </Box>

          {/* Menú hamburguesa para pantallas pequeñas, alineado a la derecha */}
          <Box sx={{ display: { xs: 'flex', md: 'none' }, marginLeft: 'auto' }}>
            <IconButton
              size="large"
              aria-label="open navigation menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="black"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{ display: { xs: 'block', md: 'none' } }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={() => handleNavigation(page)}>
                  <Typography sx={{ fontSize: '16px', fontFamily: 'Futura STD', textAlign: 'center' }}>{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Navbar;


