import React from 'react';
import { Box, Typography, Container} from '@mui/material';
import logo_2 from '../../assets/logo_2.png';
import tiktok_logo from '../../assets/tiktok_logo.png';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import IconButton from '@mui/material/IconButton';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const handleScrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth', 
  });
};

const handleScrollToSection = (sectionId) => {
  const section = document.getElementById(sectionId);
  if (section) {
    const navbarHeight = 95;
      const sectionPosition = section.getBoundingClientRect().top + window.scrollY; 
      window.scrollTo({
          top: sectionPosition - navbarHeight, 
          behavior: 'smooth', 
      });
  }
};

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: '#f8f8f8',
        py: 3,
        position: 'relative',
        bottom: 0,
        width: '100%',
      }}
    >
      <Container maxWidth="lg">
        <Typography variant="body1" color="text.secondary" sx={{ fontFamily: 'Futura STD', textAlign: 'center' }}>
          © {new Date().getFullYear()} Memótika. Todos los derechos reservados.
        </Typography>
      </Container>
    </Box>
  );
};

const GradientContainer = () => {
  const navigate = useNavigate();
  return (
    <Box
        sx={{
            background: 'linear-gradient(to top, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.5))', 
            padding: '2rem',
            position: 'relative',
            zIndex: 1,
            marginTop: 'auto',
            marginBottom: '0',
            '&::before': {
                content: '""',
                position: 'absolute',
                top: -5, 
                left: 0,
                right: 0,
                height: '7px', // Altura del efecto de difuminado
                background: 'linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.3))',
                zIndex: -1,
            },
        }}  
    >
    <Container id="footer" maxWidth="lg">  
        <Box 
            sx={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                mt: 3,  
                mb: -2,
                flexDirection: { xs: 'column', md: 'row' },
                textAlign: 'left',
            }}
        >
            <Box sx={{ flexGrow: 0.2, alignItems: 'flex-start'}}>
                <Typography variant="body2" sx={{ fontSize: '20px', fontFamily: 'Futura STDH', mb:1 }}>Legales</Typography>
                <Typography variant="body2" component="div">
                  <Link 
                    to="/politicas" 
                    onClick={() => handleScrollToTop()}
                    style={{ fontFamily: 'Futura STD', textDecoration: 'none', cursor: 'pointer', color: 'inherit' }} 
                    onMouseEnter={(e) => (e.currentTarget.style.color = '#1B8E5E')}
                    onMouseLeave={(e) => (e.currentTarget.style.color = 'inherit')}
                  >
                    Políticas de privacidad
                  </Link>
                </Typography>

                <Typography variant="body2" component="div">
                  <Link 
                    to="/condiciones" 
                    onClick={() => handleScrollToTop()}
                    style={{ fontFamily: 'Futura STD', textDecoration: 'none', cursor: 'pointer', color: 'inherit' }}  
                    onMouseEnter={(e) => (e.currentTarget.style.color = '#1B8E5E')}
                    onMouseLeave={(e) => (e.currentTarget.style.color = 'inherit')}
                  >
                    Condiciones de contratación
                  </Link>
                </Typography>

                <Typography variant="body2" component="div">
                  <Link 
                    to="/terminos" 
                    onClick={() => handleScrollToTop()}
                    style={{ fontFamily: 'Futura STD', textDecoration: 'none', cursor: 'pointer', color: 'inherit' }} 
                    onMouseEnter={(e) => (e.currentTarget.style.color = '#1B8E5E')}
                    onMouseLeave={(e) => (e.currentTarget.style.color = 'inherit')}
                  >
                    Términos de uso
                  </Link>
                </Typography>
            </Box>
            <Box sx={{ flexGrow: 0.2 }}>
                <Typography variant="body2" sx={{ fontSize: '20px', fontFamily: 'Futura STDH', mb:1 }}>Información</Typography>
                <Typography variant="body2" component="div">
                  <Link 
                    to="/conocenos" 
                    onClick={() => handleScrollToTop()}
                    style={{ fontFamily: 'Futura STD', textDecoration: 'none', cursor: 'pointer', color: 'inherit' }} 
                    onMouseEnter={(e) => (e.currentTarget.style.color = '#1B8E5E')}
                    onMouseLeave={(e) => (e.currentTarget.style.color = 'inherit')}
                  >
                    Memótika
                  </Link>
                </Typography>
                <Typography variant="body2" component="div">
                  <Link 
                    to="/" 
                    onClick={() => {
                      if (window.location.pathname === '/') {
                        handleScrollToSection('seccion-cursos');
                      } else {
                        navigate('/');
                        setTimeout(() => handleScrollToSection('seccion-cursos'), 50);
                      }
                    }}
                    style={{ fontFamily: 'Futura STD', textDecoration: 'none', cursor: 'pointer', color: 'inherit' }} 
                    onMouseEnter={(e) => (e.currentTarget.style.color = '#1B8E5E')}
                    onMouseLeave={(e) => (e.currentTarget.style.color = 'inherit')}
                  >
                    Cursos
                  </Link>
                </Typography>
              </Box> 
            <Box sx={{ flexGrow: 1 }}>
                <Typography variant="body2" sx={{ fontSize: '20px', fontFamily: 'Futura STDH' }}>Síguenos</Typography>
                
                {/* Icono de Facebook */}
                <IconButton
                    component="a"
                    href="https://www.facebook.com/profile.php?id=61566160027262" 
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ ml: -1.5,
                        color: 'black', 
                        '&:hover': { backgroundColor: 'transparent' },  
                    }}
                >
                    <FacebookIcon sx={{ fontSize: 28 }}/>
                </IconButton>

                {/* Icono de Instagram */}
                <IconButton
                    component="a"
                    href="https://www.instagram.com/memotika.cursosonline/"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                        color: 'black', 
                        '&:hover': { backgroundColor: 'transparent' },  
                    }}
                >
                    <InstagramIcon sx={{ fontSize: 28 }}/>
                </IconButton>

                {/* Icono de TikTok */}
                <IconButton
                    component="a"
                    href="https://www.tiktok.com/@memotika.cursosonline?_t=8q17ngK435Z&_r=1"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                        color: 'black', 
                        '&:hover': { backgroundColor: 'transparent' },  
                    }}
                >
                    <img
                    src={tiktok_logo}
                    alt="TikTok Logo"
                    style={{ width: '21.5px', height: '21.5px' }} 
                    />  
                </IconButton>
            </Box>

            <Link 
              to="/" 
              style={{ textDecoration: 'none' }}
              onClick={() => handleScrollToTop()} 
            >          
            <img
                src={logo_2} 
                alt="Logo_memotika" 
                style={{width: '100%', maxWidth: '370px', height: 'auto', marginBottom: '10px' }}
            />
            </Link> 
        </Box>
    </Container>
</Box>

  );
};

const App = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: 'auto',
      }}
    >
      <Box sx={{ flexGrow: 1 }}>
      </Box>
      <GradientContainer />
      <Footer />
    </Box>
  );
};

export default App;
