import React, { useState, useEffect } from 'react';
import { Box, IconButton } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { getAllcaruselimage } from '../../api/caruselimage.api'; 
import { useNavigate } from 'react-router-dom'; // Importa useNavigate

const Header = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const [images, setImages] = useState([]);
  const navigate = useNavigate(); // Inicializa useNavigate

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const caruselImages = await getAllcaruselimage(); 
        console.log('Respuesta de la API:', caruselImages); // Verifica la respuesta aquí
        
        const imageData = Array.isArray(caruselImages) 
          ? caruselImages.map(img => {
              if (img.image && img.curso) {
                return {
                  url: img.image, 
                  courseId: img.curso 
                };
              } else {
                console.warn('Elemento sin imagen o curso:', img);
                return null; // Maneja el elemento inválido
              }
            }).filter(Boolean) // Elimina elementos nulos
          : []; // Maneja el caso en que no sea un array
  
        setImages(imageData); 
      } catch (error) {
        console.error("Error fetching carousel images:", error);
      }
    };
    fetchImages();
  }, []);
  
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prev) => (prev + 1) % images.length);
    }, 8000); 

    return () => clearInterval(interval);
  }, [images.length]);

  const nextImage = () => {
    setCurrentImage((prev) => (prev + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentImage((prev) => (prev - 1 + images.length) % images.length);
  };

  const handleNavigateToCourse = (courseId) => {
    // Aquí asumes que tienes una ruta como "/cursos/:id" que muestra los detalles del curso
    navigate(`/cursos/${courseId}`); // Redirige a la vista del curso específico
  };

  if (images.length === 0) {
    return null;
  }

  return (
    <Box
      sx={{
        backgroundColor: '#111',
        position: 'relative',
        width: '100%',
        maxWidth: '1400px',
        margin: '0 auto',
        borderRadius: '30px',
        overflow: 'hidden',
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.3)',
        '&:before': {
          content: '""',
          display: 'block',
          paddingTop: '50%',
        },
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
      >
        {/* Botón anterior */}
        <IconButton
          onClick={prevImage}
          sx={{
            position: 'absolute',
            left: '15px',
            top: '50%',
            transform: 'translateY(-50%)',
            color: '#fff',
            '&:hover': { backgroundColor: 'rgba(0, 0, 0.7, 0)' },
            fontSize: { xs: '1.5rem', sm: '2rem' },
            width: { xs: '45px', sm: '55px', md: '65px' }, 
            height: { xs: '45px', sm: '55px', md: '65px' },
            zIndex: 1,
          }}
        >
          <ArrowBackIosIcon sx={{ fontSize: { xs: '1.5rem', sm: '2.5rem', md: '3rem' } }} />
        </IconButton>

        {/* Imagen del carrusel */}
        <Box
          onClick={() => handleNavigateToCourse(images[currentImage].courseId)}
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            cursor: 'pointer', 
          }}
        >
          <Box
            component="img"
            src={images[currentImage].url}
            alt="Carrusel"
            sx={{
              width: '100%',
              height: '100%',
              objectFit: 'cover', 
              backgroundColor: '#ffffff',
            }}
          />
        </Box>

         {/* Botón siguiente */}
         <IconButton
          onClick={nextImage}
          sx={{
            position: 'absolute',
            right: '10px',
            top: '50%',
            transform: 'translateY(-50%)',
            color: '#fff',
            '&:hover': { backgroundColor: 'rgba(0, 0, 0.7, 0)' },
            fontSize: { xs: '1.5rem', sm: '2rem' },
            width: { xs: '45px', sm: '55px', md: '65px' }, 
            height: { xs: '45px', sm: '55px', md: '65px' },
          }}
        >
          <ArrowForwardIosIcon sx={{ fontSize: { xs: '1.5rem', sm: '2.5rem', md: '3rem' } }} />
        </IconButton>
      </Box>
    </Box>
  );
};

export default Header;
