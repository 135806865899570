import React from 'react';
import { Box, Grid, Typography, Link } from '@mui/material';
import Banner from '../../assets/img/banner_legal.png'; 

const Conditions = () => {
  return (
    <Grid container spacing={2} >
      {/* Banner en la parte superior */}
      <Grid item xs={12}>
        <Box
          sx={{
            width: '100%',
            height: 'auto',
            overflow: 'hidden',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mb: '20px', 
          }}
        >
          <img
            src={Banner} 
            alt="Términos y Condiciones"
            style={{
              width: '100%', 
              height: 'auto', 
            }}
          />
        </Box>
      </Grid>

      {/* Cuadro de texto con el título centrado */}
      <Grid item xs={12}>
        <Box
          sx={{
            width: '85%',
            margin: '0 auto',
            padding: '20px',
          }}
        >
          <Typography
            variant="h4"
            align="center" 
            sx={{ fontSize: { xs: '30px', sm: '40px', md: '45px' }, fontFamily: 'Futura Medium Italic', color: '#0a3531',  mb: '40px' }}
          >
            Condiciones de Contratación
          </Typography>

          <Typography variant="body1" sx={{ textAlign: 'justify', fontSize: { xs: '15px', sm: '20px', md: '20px' }, whiteSpace: 'pre-wrap', fontFamily: 'Futura Book Font', color: '#434342',  mb: '40px', mr: { xs: '30px', sm: '20px', md: '0px' } }}>
          {"Condiciones de Contratación\n\n"}
          {"Al adquirir uno de nuestros cursos, aceptas estas condiciones de contratación, las cuales describen los términos y procedimientos con el proceso de compra, acceso a los cursos y las políticas de garantía y devolución.\n\n"}

          {"Descripción de los servicios\n"}
          {"Ofrecemos cursos en línea que se detallan en nuestra página web. Cada curso cuenta con una página de presentación que incluye la siguiente información:\n"}
          {" • Descripción del curso\n"}
          {" • Duración del curso\n"}
          {" • Objetivos y contenido\n"}
          {" • Costo (si aplica)\n\n"}

          {"Nos reservamos el derecho de modificar la oferta de cursos en cualquier momento, incluyendo actualizaciones al contenido, el costo o la disponibilidad de los mismos.\n\n"}

          {"Proceso de compra\n"}
          {"1. Explora nuestro catálogo y elige el curso que más te interese.\n"}
          {"2. Haz clic en el curso que te interese para acceder a los detalles.\n"}
          {"3. Completa el formulario de contacto o comunícate directamente con nosotros por WhatsApp.\n"}
          {"4. Recibe la información de pago y confirma tu inscripción.\n"}
          {"5. Una vez inscrito, tendrás acceso al contenido y a las clases de tu curso.\n\n"}

          {"Métodos de pago\n"}
          {"Para proceder con la compra del curso, el método de pago se realizará de manera directa al hacer clic en el botón de compra para dirigirte a la pasarela de pago, donde cancelarás en tu moneda local, según los medios habilitados para este proceso. Además, contamos con nuestro chat privado en nuestra línea de WhatsApp 3215956248 para coordinar los detalles del pago.\n\n"}

          {"Para solicitar devolución o reembolso, se debe contar con el plazo de garantía de 7 días una vez realizada la compra, siguiendo los pasos que se encuentran en el siguiente enlace: "}
          <Link style={{ color: '#1B8E5E', textDecorationColor: '#1B8E5E',
           }}href="https://refund.hotmart.com/" target="_blank" rel="noopener noreferrer">
            {"https://refund.hotmart.com/"}
          </Link>
          {". Luego, introduce el número de transacción de tu compra. Puedes encontrar este número en el correo electrónico que recibiste con los datos de acceso al producto (empieza por dos letras y una serie de números, como HP000000000). Después de realizar los pasos, recibirás un código de verificación de seguridad en el correo electrónico que registraste con tu compra.\n\n"}

          {"Derechos y Obligaciones del Usuario\n"}
          {"El usuario se compromete a:\n"}
          {"• Proporcionar información veraz y completa durante el proceso de compra.\n"}
          {"• Utilizar los cursos adquiridos de manera personal y no compartir los accesos o contenidos con terceros.\n"}
          {"• Respetar los derechos de propiedad intelectual y no distribuir el contenido del curso sin autorización previa.\n\n"}

          {"Modificaciones de los cursos\n"}
          {"Nos reservamos el derecho de modificar o actualizar los cursos de nuestra página web en cualquier momento para brindar una mejor experiencia en capacitación a nuestros estudiantes.\n"}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Conditions; 