import {BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import React from 'react';
import Error404 from './containers/errors/Error404';
import Home from './containers/pages/Home';
import Conocenos from './containers/pages/Conocenos';
import Politicas from './containers/pages/Politicas';
import Terminos from './containers/pages/Terminos';
import Cursos from './containers/pages/Cursos';
import Condiciones from './containers/pages/Condiciones';
import Footer from "./components/navigation/Footer"
import Navbar from "./components/navigation/Navbar"
import Iconosredes from './components/navigation/Iconosredes';

function App() {
  return (
    <div>
      <Router>
      <Navbar />
      <Iconosredes />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/conocenos" element={<Conocenos />} />
          <Route path="/politicas" element={<Politicas />} />
          <Route path="/terminos" element={<Terminos />} />
          <Route path="/condiciones" element={<Condiciones />} />
          <Route path="/home" element={<Home />} />
          <Route path="/cursos/:id" element={<Cursos />} />
          <Route path="*" element={<Error404 />} /> 
        </Routes>
      <Footer />
      </Router>
    </div>
  );
}

export default App;
