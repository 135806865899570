import React from 'react';
import Memotika from "../../components/conocenos/Memotika"

function Conocenos(){
    return(
        <div>
            <Memotika/>    
        </div>
    )
}

export default Conocenos