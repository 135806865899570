import Cursos from "../../components/home/Cursos";
import Header from "../../components/home/Header";
import Informacion from "../../components/home/Informacion";
import Reseñas from "../../components/home/Reseñas";
import Problemas from "../../components/home/Problems";

function Home(){
    return(
        <div>
            <section id="seccion-header">
                <Header/>
            </section>
                <Informacion/>
                <Problemas/>
            <section id="seccion-cursos">
                <Cursos/>
            </section>
                <Reseñas/>
        </div>
    )
}

export default Home;