import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, IconButton, Collapse } from '@mui/material';
import VerifiedIcon from '@mui/icons-material/Verified';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { getAllcourses } from '../../api/courses.api';
import { useParams } from 'react-router-dom';
import WhatsApp from '../../assets/img/whatsapp_button.png';
import Duracion from '../../assets/img/duracion.svg';
import Modulos from '../../assets/img/modulos.svg';
import decorImage from '../../assets/img/Marca Memótika-20.png'; 
import avion from '../../assets/img/avion.png';
import garantia from '../../assets/img/garantia.png';
import marcamemotika from '../../assets/img/Trofeo.svg';
import ModuloTutor from './Tutor';
import axios from 'axios';

const PageCurso = () => {
  const [course, setCourses] = useState({modulos: []});
  const { id } = useParams();
  const [whatsappNumber, setWhatsappNumber] = useState('');
  const [expandedModule, setExpandedModule] = useState(null); 

  const handleToggle = (index) => {
    setExpandedModule(prev => (prev === index ? null : index));
  };

  useEffect(() => {
    async function loadCourses() {
      try {
        const response = await getAllcourses();
        const selectedCourse = response.find(c => c.id === parseInt(id));
        setCourses(selectedCourse);
        console.log(selectedCourse);
      } catch (error) {
        console.error('Error fetching courses:', error);
      }
    }

    loadCourses();
  }, [id]);

  useEffect(() => {
    const fetchWhatsappNumber = async () => {
      try {
        const response = await axios.get('https://memotika.com/main/api/whatsApp/');
        console.log('WhatsApp API response:', response.data);
  
        if (response.data.length > 0) {
          setWhatsappNumber(response.data[0].phone_number);
        } else {
          console.log('No WhatsApp number available');
        }
      } catch (error) {
        console.error('Error fetching WhatsApp number:', error);
      }
    };
  
    fetchWhatsappNumber();
  }, []);

  const handleClick = () => {
    if (whatsappNumber) {
      window.open(`https://wa.me/${whatsappNumber}`, '_blank');
    } else {
      console.log('WhatsApp number not available');
    }
  };

  return (
    <Box sx={{ padding: { xs: '0px 10px', sm: '0px 30px', md: '0px 40px', overflow: 'hidden' } }}>
      {/* Contenedor Principal */}
      <Box sx={{ padding: '10px' }}>
        {/* Contenedor de la Imagen y Título */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            gap: { xs: '10px', md: '50px' },
            mb:4,
          }}
        >
          {/* Imagen del Curso */}
          <Box
            sx={{
              top:-10,
              width: { xs: '100%', md: '150%' },
              borderRadius: '20px',
              overflow: 'hidden',
              mt: { xs: '10px', md: '40px' },
              boxShadow:
                '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
            }}
          >
            <img
              src={course.imagen}
              alt="Imagen Curso"
              style={{
                width: '100%',
                height: 'auto',
                display: 'block',
              }}
            />
          </Box>

          {/* Información del Curso */}
          <Box sx={{ flexGrow: 1 }}>
            <Typography
              sx={{
                fontFamily: 'Futura STDH',
                fontSize: { xs: '25px', sm: '30px', md: '35px' },
                color: '#0a3531',
                mt: { xs: 1, md: 5 },
                textAlign: { xs: 'center', md: 'left' },
                lineHeight: { xs: 1.2, md: 1.4 },
              }}
            >
              {course.titulo}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontFamily: 'Futura STD',
                fontSize: { xs: '16px', sm: '18px', md: '20px' },
                mt: { xs: 1, md: 3 },
                mb: { xs: 1, md: 0 },
                textAlign: { xs: 'center', sm:'center', md: 'left' },
              }}
            >
              Código: {`00${course.id}`}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: { xs: 'center', md: 'flex-start' }, 
                width: '100%', 
              }}
            >
              <Typography
                component="span"
                sx={{
                  display: 'flex',
                  fontFamily: 'Futura STDH',
                  fontSize: { xs: '1.2rem', md: '1.4rem' },
                  textAlign: { xs: 'center', md: 'left' }, 
                  marginRight: '5px',
                }}
              >
                Incluye Certificado
              </Typography>
              <VerifiedIcon 
                sx={{ 
                  fontSize: { xs: '1.5rem', md: '1.8rem' }, 
                  color: '#5182e0',
                  marginRight: '5px',
                }} 
              />
            </Box> 
            {/* Mostrar el precio si está disponible */}
            {course.precio && course.precio.amount && course.precio.currency && (
              <Box
                sx={{
                  fontFamily: 'Futura STDH',
                  fontSize: { xs: '1.4rem', md: '1.6rem' },
                  color: '#146d48',
                  fontWeight: 'bold',
                  textAlign: { xs: 'center', sm:'center', md: 'left' },
                }}
              >
                Precio: {course.precio.currency} ${course.precio.amount.toLocaleString('es-CO')}
              </Box>
            )}
            {/* Información adicional */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: { xs: 'center', sm: 'center', md: 'left' },
                mt: '20px',
                gap: '20px',
                flexWrap: 'wrap',
              }}
            >
              {/* Duración */}
              <Box
                sx={{
                  width: { xs: '140px', sm: '160px', md: '200px' },
                  height: { xs: '80px', sm: '90px', md: '100px' },
                  borderRadius: '20px',
                  backgroundImage: `url(${Duracion})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  position: 'relative',
                  boxShadow:
                    '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontFamily: 'Futura STD',
                    fontSize: { xs: '14px', sm: '16px', md: '18px' },
                    position: 'absolute',
                    top: '60%',
                    left: '40%',
                    transform: 'translate(-50%, -30%)',
                    textAlign: 'center',
                  }}
                >
                  {course.duracion}
                </Typography>
              </Box>

              {/* Número de Módulos */}
              <Box
                sx={{
                  width: { xs: '140px', sm: '160px', md: '200px' },
                  height: { xs: '80px', sm: '90px', md: '100px' },
                  borderRadius: '20px',
                  backgroundImage: `url(${Modulos})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  position: 'relative',
                  boxShadow:
                    '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontFamily: 'Futura STD',
                    fontSize: { xs: '14px', sm: '16px', md: '18px' },
                    position: 'absolute',
                    top: '60%',
                    left: '40%',
                    transform: 'translate(-50%, -30%)',
                    textAlign: 'center',
                  }}
                >
                  {`${course.modulos.length > 0 ? course.modulos.length : 'N/A'}`}
                </Typography>
              </Box>
            </Box>

            {/* Botónes de adquirir y WhatsApp */}
            <Box 
              sx={{ 
                padding: '2px 5px',
                gap: 2, 
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },  
                justifyContent: { xs: 'center', sm: 'center', md: 'left' }, 
                mt: { xs: '20px', md: '30px' },
                mb: { xs: -2, md: 0 }, 
              }}
            >
              {course.link && (
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: '#104447',
                    '&:hover': { backgroundColor: '#b0d31d' },
                    fontSize: { xs: '16px', sm: '18px', md: '20px' },
                    fontFamily: 'Futura STDH',
                    textTransform: 'none',
                    borderRadius: '50px',
                    padding: '10px 30px',
                  }}
                  onClick={() => window.location.href = course.link}
                >
                  ¡Adquirir Ahora!
                </Button>
              )}
              <Button
                variant="contained"
                sx={{
                  backgroundColor: '#25D366',
                  color: '#FFFFFF',
                  '&:hover': {
                    backgroundColor: '#128C7E',
                  },
                  fontFamily: 'Futura STDH',
                  padding: '10px 30px',
                  borderRadius: '50px',
                  display: 'flex',
                  alignItems: 'center',
                  textTransform: 'none',
                  fontSize: { xs: '16px', sm: '18px', md: '20px' },
                }}
                onClick={handleClick}  
              >
                <img
                  src={WhatsApp}
                  alt="WhatsApp Logo"
                  style={{
                    width: '40px',
                    height: '40px',
                    padding: '5px',
                  }}
                />
                ¡Click Aquí! Para más información
              </Button>
            </Box>

          </Box>
        </Box>
        
        {/* Título "Nuestros Módulos" */}
        <Box sx={{ display: 'flex', alignItems: 'center', ml: { xs: 0, md: 0 }, mt: { xs: 0, md: -5 } }}>
            <Box
              component="img"
              src={decorImage} 
              alt="Decoración"
              sx={{
                width: { xs: '120px', md: '160px' }, 
                height: { xs: '120px', md: '160px' },
                
              }}    
            />
            <Typography variant="h5" component="h5" sx={{color:'#04373a', fontFamily: 'Futura STDH', fontSize: { xs: '40px', md: '50px' }, mb: { xs: 3, md: 1}, mr: 5 }}>
              Nuestros Módulos
            </Typography>
        </Box>
        {/* Lista de Módulos */}
        <Box
          sx={{
            position: 'relative',
            gap: '10px',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            maxWidth: '1400px',
            margin: '0 auto',
          }}
        >
          {course.modulos && course.modulos.length > 0 ? (
            course.modulos.map((modulos, index) => (
              <Box
                key={modulos.id || index}
                sx={{
                  display: 'flex',
                  alignItems: 'stretch',
                  border: '1px solid #ccc',
                  borderRadius: '40px',
                  overflow: 'hidden',
                  marginBottom: '5px',
                  backgroundColor: '#e6f2f2',
                }}
              >
                {/* Caja del número que está fuera */}
                <Box
                  sx={{
                    backgroundColor: '#b0d31d',
                    color: '#fff',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '40px 0 0 40px',
                    minWidth: '50px',
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      fontFamily: 'Futura STDH',
                      fontSize: { xs: '20px', sm: '30px' },
                      color: '#fff',
                    }}
                  >
                    {index + 1}.
                  </Typography>
                </Box>

                {/* Caja que contiene el título y la descripción */}
                <Box
                  sx={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                  }}
                >
                  {/* Título y botón de expansión */}
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      padding: '5px',
                      cursor: 'pointer',
                    }}
                    onClick={() => handleToggle(index)}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        fontFamily: 'Futura STDH',
                        fontSize: { xs: '15px', sm: '20px' },
                        color: '#0a3531',
                      }}
                    >
                      {modulos.titulo}
                    </Typography>
                    <IconButton>
                      {expandedModule === index ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                  </Box>

                  {/* Descripción del módulo, solo se muestra si está expandido */}
                  <Collapse in={expandedModule === index} timeout="auto" unmountOnExit>
                    <Box
                      sx={{
                        flex: { md: '1 1 calc(25% - 15px)' },
                        padding: '10px',
                        backgroundColor: '#f7f7f7',
                        borderRadius: '0 0 40px 40px',
                        marginTop: '10px',
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          fontFamily: 'Futura STD',
                          fontSize: { xs: '12px', sm: '15px' },
                          fontWeight: 'bold',
                          color: '#434342',
                        }}
                      >
                        {(modulos.descripcion || '').split('\r\n').map((item, index) => (
                          <li key={index}>{item}</li>
                        ))}
                      </Typography>
                    </Box>
                  </Collapse>
                </Box>
              </Box>
            ))
          ) : (
            <Typography
              variant="body1"
              sx={{
                fontFamily: 'Futura STDH',
                fontSize: { xs: '14px', sm: '16px', md: '18px' },
                textAlign: 'center',
              }}
            >
              No hay módulos disponibles.
            </Typography>
          )}
        {/* Información Tutor */}
        <ModuloTutor />
      </Box>          
      {/* Sección principal */}
      <Box
        sx={{
          width: {xs:'90%', sm:'92%', md:'95%'},
          margin: '0 auto',
          padding: '20px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {/* Sección ¿Qué incluye este curso? */}
        <Box
          sx={{
            backgroundColor: '#b0d31d', 
            borderRadius: '0 0 40px 40px', 
            padding: '20px',
            width: '100%',
          }}
        >
          <Box
            sx={{
              backgroundColor: '#0a3531',
              borderRadius: '40px', 
              padding: '10px',
              position: 'relative', 
            }}
          >
            <Box
              component="img"
              src={avion}
              alt="Decoración"
              sx={{
                position: 'absolute',

                right: 0,
                mt:{ xs:2.3, sm:0, md:0},
                width: { xs: '50px', sm:'70px', md: '100px' },
              }}
            />
            {/* Título ¿Qué incluye este curso? */}
            <Typography
              variant="h5"
              component="h5"
              sx={{
                color: '#fff',
                fontFamily: 'Futura STDH',
                fontStyle: 'italic',
                fontSize: { xs: '30px', md: '40px' },
                textAlign: 'center',
              }}
            >
              ¿Qué incluye este curso?
            </Typography>
            <Box
              component="div"
                sx={{
                  position: 'absolute',
                  top: {xs:'350%', sm:'300%', md:'200%'},
                  left:{xs:'-15%', sm:'-10%', md:'-5%'},
                  transform: 'translateX(-50%)',
                  width: { xs: '250px', sm:'350px', md: '400px' },  
                  height: { xs: '250px', sm:'350px', md: '400px' }, 
                  borderRadius: '200px 200px 200px 200px', 
                  backgroundColor: 'rgba(181, 230, 29, 0.3)',
                  zIndex: -1,
                }}
            />
          </Box>
        </Box>

        {/* Descripción completa sin fondo */}
        <Typography
          variant="body1"
          sx={{
            textAlign: 'justify',
            fontSize: { xs: '15px', sm: '20px', md: '20px' },
            whiteSpace: 'pre-wrap',
            fontFamily: 'Futura Book Font',
            color: '#434342',
            mb: '20px',
            mt: '20px',
            mr: { xs: 3, md: 0 },
          }}
        >
          {course.descripcion_completa}
        </Typography>

        {/* Sección Comienza tu éxito */}
        <Box
          sx={{
            backgroundColor: '#0a3531', 
            borderRadius: '40px', 
            position: 'relative',
            width: '100%',
            mb: '10px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mt: '20px',
          }}
        >
          {/* Imagen en el extremo izquierdo */}
          <Box
            component="img"
            src={marcamemotika}
            alt="Decoración izquierda"
            sx={{
              position: 'absolute',
              left: {xs:'-30px', sm:'-40px', md:'-60px'}, 
              top: '40%',
              transform: 'translateY(-50%)', 
              width: { xs: '70px', sm:'100px', md: '140px' },
            }}
          />

          <Typography
            variant="h6"
            component="h6"
            sx={{
              color: '#fff',
              fontFamily: 'Futura STDH',
              fontStyle: 'italic',
              fontSize: { xs: '30px',sm:'35px', md: '40px' },
              textAlign: 'center',
              zIndex: 1,
            }}
          >
            Comienza tu éxito
          </Typography>
          {/* Imagen en el extremo derecho */}
          <Box
            component="img"
            src={marcamemotika}
            alt="Decoración derecha"
            sx={{
              position: 'absolute',
              right: {xs:'-30px', sm:'-40px', md:'-60px'}, 
              top: '40%',
              transform: 'translateY(-50%)', 
              width: { xs: '70px', sm:'100px', md: '140px' }, 
            }}
          />
        </Box>
        {/* Características completas */}
        <Box sx={{ position: 'relative', mt: '20px' }}> {/* Contenedor relativo para texto + semicírculo */}
          <Typography
            variant="body1"
            sx={{
              textAlign: 'justify',
              fontSize: { xs: '15px', sm: '20px', md: '20px' },
              whiteSpace: 'pre-wrap',
              fontFamily: 'Futura Book Font',
              color: '#434342',
              mt: '10px',
              mr: { xs: 3, md: 0 },
            }}
          >
            {course.caracteristicas_completas}
          </Typography>
          <Box
            component="div"
            sx={{
              position: 'absolute',
              bottom: '-50px',
              left: '50%',
              transform: 'translateX(-50%)',
              width: { xs: '230px', sm:'350px', md: '400px' },  
              height: { xs: '115px',sm:'175px',  md: '200px' }, 
              borderRadius: '200px 200px 0 0', 
              backgroundColor: 'rgba(181, 230, 29, 0.3)',
              zIndex: -1,
            }}
          />
        </Box>
      </Box>
      {/* Sección de Garantía */}
      <Box
        sx={{
          mt: '30px',
          p: '20px',
          backgroundColor: '#c8e7dc',
          borderLeft: '5px solid #2b4c43',
          borderRadius: '20px',
          textAlign: 'center',
        }}
      >
        <Typography variant="h5" sx={{ color: '#04373a', fontFamily: 'Futura Book Font', fontWeight: 'bold' }}>
        ¡Tranquilidad y Confianza!
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontSize: { xs: '14px', sm: '18px', md: '18px' },
            color: 'black',
            mt: '10px',
            fontFamily: 'Futura Book Font',
          }}
        >
          Si el curso no cumple con tus expectativas, en <strong>Memótika</strong>, te ofrecemos una garantía de reembolso del 100% de tu dinero durante los primeros 7 días,
          sin preguntas ni complicaciones.
        </Typography>
        {/* Imagen de Garantía */}
        <Box
          component="img"
          src={garantia}
          alt="Garantía de 7 días"
          sx={{ 
            maxWidth: {xs:'150px', sm:'180px',md:'200px'}, 
            mt: '20px', 
            mx: 'auto' 
          }}
        />
      </Box>
        </Box>
    </Box>
  );
};

export default PageCurso;
