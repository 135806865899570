import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Box, Typography, Avatar } from '@mui/material';
import { Person } from '@mui/icons-material'; 
import Image from '../../assets/img/Marca Memótika-21.png'; 

const Reseñas = () => {
  const [resenas, setResenas] = useState([]); 

  useEffect(() => {
    const fetchResenas = async () => {
      try {
        const response = await axios.get('https://memotika.com/courses/api/courses/');
        if (Array.isArray(response.data)) {
          const allResenas = response.data.map(curso => curso.resenas).flat();
          setResenas(allResenas);
        } else {
          console.error('La respuesta no es un array:', response.data);
        }
      } catch (error) {
        console.error('Error al obtener las reseñas:', error);
      }
    };

    fetchResenas();
  }, []);

  if (resenas.length === 0) {
    return <Typography></Typography>;
  }

  return (
    <Box sx={{ py: 4, backgroundColor: 'white' }}>
      <Container>
        {/* Título de la sección con imagen */}
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: 'center', justifyContent: 'center', mb: 4 }}>
          <Box
            sx={{
              position: 'relative',
              width: { xs: 100, md: 150 },
              height: { xs: 100, md: 150 },
              backgroundColor: 'white',
              borderRadius: '50%',
              display: 'flex',
              zIndex: 2,
              alignItems: 'center',
              justifyContent: 'center',
              boxShadow: 1,
              mb: { xs: 0.5, md: 0 }, 
              mr: { md: -5 }, 
            }}
          >
            <img
              src={Image}
              alt="Logo"
              style={{ borderRadius: '50%', width: '130%', height: '130%', objectFit: 'cover' }} 
            />
          </Box>
          <Box
            sx={{
              backgroundColor: 'white',
              padding: '10px 40px',
              borderRadius: 20,
              boxShadow: 1,
              zIndex: 1,
              mb: 2,
              textAlign: { xs: 'center', md: 'right' },
            }}
          >
            <Typography variant="h4" component="h2" sx={{ margin: 1, fontFamily: 'Futura Bold', color: '#04373a' }}>
              Nuestras Reseñas
            </Typography>
          </Box>
        </Box>

        <Box sx={{ textAlign: 'center', mb: 4 }}>
          <Typography variant="h6" component="h2" sx={{ fontFamily: 'Futura Book font' }}>
            Descubre cómo nuestra comunidad ha transformado sus resultados. ¡Tú podrías ser el próximo!
          </Typography>
        </Box>

        {/* Mapeo de las reseñas para crear un diseño recursivo */}
        <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
          {resenas.map((resena) => (
            <Box
              key={resena.id}
              sx={{
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                mb: 4,
                width: { xs: '100%', sm: '48%' }, 
                flexDirection: 'row',
              }}
            >
              {/* Semicírculo verde oscuro detrás del avatar */}
              <Box sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '15%', 
                height: '100%',
                backgroundColor: '#04373a',
                borderBottomLeftRadius: 100,
                borderTopLeftRadius: 100,
                zIndex: 0,
              }} />

              {/* Avatar del usuario */}
              <Box
                sx={{
                  width: { xs: 60, sm: 80, md: 100 },
                  height: { xs: 60, sm: 80, md: 100 },
                  borderRadius: '50%',
                  border: '2px solid white',
                  overflow: 'hidden',
                  position: 'relative',
                  zIndex: 1,
                  marginRight: 0, 
                }}
              >
                <Avatar sx={{ width: '100%', height: '100%', backgroundColor: '#fff' }}>
                  <Person sx={{ fontSize: { xs: 24, sm: 36, md: 48 }, color: '#2A7E5F' }} /> {/* Icono responsivo */}
                </Avatar>
              </Box>

              {/* Contenedor de la reseña */}
              <Box
                sx={{
                  flexGrow: 1,
                  backgroundImage: `url(${resena.imagen})`, 
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  borderRadius: 2,
                  boxShadow: 5,
                  height: { xs: 120, sm: 140, md: 160 }, 
                  marginLeft: -3, 
                  width: '80%',
                  marginRight: '10%',
                }}
              />
            </Box>
          ))}
        </Box>
      </Container>
    </Box>
  );
};

export default Reseñas;

