import React from 'react';
import AppCondiciones from "../../components/navigation/AppCondiciones"

function Condiciones(){
    return(
        <div>
            <AppCondiciones/>    
        </div>
    )
}

export default Condiciones