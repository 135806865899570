import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import Banner from '../../assets/img/banner_legal.png'; 

const Politics = () => {
  return (
    <Grid container spacing={2} >
      {/* Banner en la parte superior */}
      <Grid item xs={12}>
        <Box
          sx={{
            width: '100%',
            height: 'auto',
            overflow: 'hidden',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mb: '20px', 
          }}
        >
          <img
            src={Banner} 
            alt="Términos y Condiciones"
            style={{
              width: '100%', 
              height: 'auto', 
            }}
          />
        </Box>
      </Grid>

      {/* Cuadro de texto con el título centrado */}
      <Grid item xs={12}>
        <Box
          sx={{
            width: '85%',
            margin: '0 auto',
            padding: '20px',
          }}
        >
          <Typography
            variant="h4"
            align="center" 
            sx={{ fontSize: { xs: '30px', sm: '40px', md: '45px' }, fontFamily: 'Futura Medium Italic', color: '#0a3531',  mb: '40px' }}
          >
            Políticas de Privacidad
          </Typography>

          <Typography variant="body1" sx={{ textAlign: 'justify', fontSize: { xs: '15px', sm: '20px', md: '20px' }, whiteSpace: 'pre-wrap', fontFamily: 'Futura Book Font', color: '#434342',  mb: '40px', mr: { xs: '30px', sm: '20px', md: '0px' } }}>
          Responsable del Tratamiento de Datos
          <br /><br />
          La entidad responsable del tratamiento de los datos personales proporcionados a través de esta página web es Memótika y contacto a través del correo electrónico memotika.cursosonline@gmail.com.
          <br /><br />
          Datos Personales Recogidos
          <br /><br />
          En nuestra página web, recogemos los siguientes datos personales:<br />
          - Información de contacto (nombre, correo electrónico, número de celular) proporcionada al completar formularios de contacto o al adquirir un curso.<br />
          - Información sobre el uso de la página web, como la dirección IP, navegador, y dispositivo utilizado para acceder a nuestro sitio web, a través de cookies y otras tecnologías de seguimiento.<br />
          - Datos adicionales relacionados con la compra o adquisición de cursos, medio de pasarela de pago.
          <br /><br />
          Finalidad del Tratamiento de Datos
          <br /><br />
          Los datos personales recogidos en nuestra página web se utilizan con los siguientes fines:<br />
          - Facilitar la navegación y el uso de la plataforma.<br />
          - Gestionar las solicitudes de información y las consultas realizadas a través de los formularios de contacto.<br />
          - Gestionar la compra y adquisición de cursos, incluyendo el envío de confirmaciones y actualizaciones.<br />
          - Enviar información relevante sobre los cursos disponibles, así como noticias y promociones, siempre que el usuario haya dado su consentimiento.<br />
          - Mejorar la experiencia del usuario mediante el análisis de la interacción con la página web, lo que nos permite optimizar su rendimiento y diseño.
          <br /><br />
          Legitimación para el Tratamiento de Datos
          <br /><br />
          El tratamiento de los datos personales proporcionados por los usuarios se realiza con base en:<br />
          - El consentimiento del usuario, al completar los formularios de contacto o adquirir un curso.<br />
          - La necesidad de gestionar las solicitudes y transacciones realizadas a través de la página web.
          <br /><br />
          Medidas de Seguridad
          <br /><br />
          Nos comprometemos a proteger tus datos personales mediante la implementación de medidas de seguridad técnicas y organizativas adecuadas para garantizar su confidencialidad, integridad y disponibilidad. Sin embargo, no podemos garantizar que los sistemas de seguridad sean completamente infalibles, por lo que recomendamos a los usuarios que tomen precauciones adicionales para proteger su información.
          <br /><br />
          Conservación de los Datos
          <br /><br />
          Los datos personales se conservarán durante el tiempo necesario para cumplir con la finalidad para la que fueron recogidos, o hasta que el usuario solicite su eliminación, siempre que no exista una obligación legal que requiera su conservación.
          <br /><br />
          Terceros
          <br /><br />
          Tus datos personales no serán compartidos con terceros, salvo en los casos en que sea necesario para el funcionamiento de la página web (como servicios de pago o de alojamiento web), o cuando exista una obligación legal de hacerlo. En cualquier caso, nos aseguramos de que cualquier tercero que acceda a tus datos cumpla con las normativas de protección de datos aplicables.
          <br /><br />
          Modificaciones en la Política de Privacidad
          <br /><br />
          Nos reservamos el derecho de modificar esta Política de Privacidad en cualquier momento. Cualquier cambio será comunicado a través de esta página. Recomendamos revisar esta política periódicamente para estar informado de las actualizaciones.
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Politics; 

