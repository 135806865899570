import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import Marca1 from '../../assets/img/Marca Memótika-21.png'; 
import Servicios from '../../assets/img/servicios.jpg'; 
import Valores from '../../assets/img/valores.png'; 

const PortfolioServicios = () => {
  return (
    <Box sx={{ padding: {xs: '15px', md: '30px'} }}>
      {/* Portafolio de Servicios */}
      <Grid container spacing={2} sx={{ mb: '20px', mt: {xs: '0px', md: '0px'} }}>
        <Grid item xs={12} md={6}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: { xs: 'column', sm: 'row' } }}>
              <Box
                component="img"
                src={Marca1} 
                alt="Decoración"
                sx={{
                  width: { xs: '90px', sm: '90px', md: '180px' }, 
                  height: { xs: '90px', sm: '90px', md: '180px' }, mr: 1}}
              />
              <Typography variant="h5" component="h5" sx={{color:'#1B8E5E', fontFamily: 'Futura STDH', fontSize: { xs: '30px', sm: '40px', md: '60px' } }}>
                CONÓCENOS
              </Typography>
            </Box>
          </Box>
          <Typography variant="body1" sx={{ fontFamily: 'Futura Book Font', fontSize: { xs: '18px', sm: '20px', md: '25px' }, marginTop: { xs: '20px', sm: '10px', md: '0px' }, textAlign: 'justify', ml: { xs: '40px', sm: '40px', md: '120px'  }, mr: { xs: '40px', sm: '40px', md: '50px'  } }}>
          <span style={{ fontWeight: 'bold' }}>Memotika</span>, se enfoca en el desarrollo de cursos online para fortalecer las áreas críticas del conocimiento en el mundo digital donde los estudiantes se capacitarán en temáticas como tecnología educativa, emprendimiento y diseño mediante cursos y diplomados diseñados para fortalecer sus habilidades tecnológicas y digitales necesarias para sobresalir en sus carreras o proyectos personales.
          </Typography>
        </Grid>
        
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              width: '93%',
              height: 'auto',
              overflow: 'hidden',
              borderRadius: '20px',
              mt: { xs: '30px', sm: '30px', md: '140px' },
              boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
            }}
          >
            <img
              src={Servicios}
              alt="Portafolio"
              style={{ 
                width: '100%',  
                height: 'auto', 
                display: 'block',
              }}
            />
          </Box>
        </Grid>
      </Grid>

      {/* Valores */}
      <Grid 
        container 
        spacing={2} 
        alignItems="center" 
        sx={{ padding: '20px' }} 
      >
        {/* Columna de la Imagen */}
        <Grid item xs={12} md={6}>
          <Box
            component="img"
            src={Valores} 
            alt="Valores"
            sx={{ width: '100%', height: 'auto', display: 'block' }} 
          />
        </Grid>

        {/* Columna del Texto */}
        <Grid item xs={12} md={6}>
          <Box sx={{ padding: '20px' }}>
            <Typography variant="body1" sx={{ fontSize: { xs: '18px', sm: '20px', md: '25px' }, fontFamily: 'Futura Book Font' }}>
              <span
              style={{ 
                fontSize: '25px', 
                fontFamily: 'Futura STDH', 
                color: '#0a3531', 
              }}>
              Empoderar:</span> Enfocarse en el desarrollo personal y profesional de los estudiantes.<br /><br />

              <span
              style={{ 
                fontSize: '25px', 
                fontFamily: 'Futura STDH', 
                color: '#0a3531', 
              }}>
              Evolución:</span> Promover el aprendizaje continuo y actualizado del contenido ofrecido.<br /><br />

              <span
              style={{ 
                fontSize: '25px', 
                fontFamily: 'Futura STDH', 
                color: '#0a3531', 
              }}>
              Innovación:</span> Fomentar la creatividad y la adopción de nuevas tecnologías en los métodos educativos.<br /><br />

              <span
              style={{ 
                fontSize: '25px', 
                fontFamily: 'Futura STDH', 
                color: '#0a3531', 
              }}>
              Transformación:</span> Impactar de manera positiva en los estudiantes para que alcancen sus éxitos personales y profesionales en el mundo digital.<br /><br />

              <span
              style={{ 
                fontSize: '25px', 
                fontFamily: 'Futura STDH', 
                color: '#0a3531', 
              }}>
              Aprendizaje Continuo:</span> Fomentar la importancia del aprendizaje a lo largo de la vida para alcanzar sus objetivos personales o profesionales.<br />
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PortfolioServicios;
