import { Box, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom'; 
import errorImage from '../../assets/logo_1.png'; 

function Error404() {
  return (
    <Box
      sx={{
        minHeight: '80vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#f4f4f4', 
        padding: { xs: '20px', md: '50px' },
      }}
    >
      {/* Contenedor del texto Error + 404 + | + Imagen */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row', 
          alignItems: 'center', 
          mb: 2,
        }}
      >
        {/* Texto "Error" más pequeño */}
        <Typography
          variant="h4"
          sx={{
            fontSize: { xs: '20px', md: '40px' }, 
            fontFamily: 'Futura Light BT',
            color: '#114143',
            fontWeight: 'bold',
            mr: 1, 
          }}
        >
          Error
        </Typography>

        {/* Texto "404" más grande */}
        <Typography
          variant="h1"
          sx={{
            fontSize: { xs: '80px', md: '100px' }, 
            fontFamily: 'Futura Light BT',
            color: '#114143',
            fontWeight: 'bold',
            mr: 3, 
          }}
        >
          404
        </Typography>

        {/* Símbolo "pipe" | */}
        <Typography
          variant="h2"
          sx={{
            fontSize: { xs: '40px', md: '60px' },
            fontFamily: 'Futura Light BT',
            color: '#114143',
            fontWeight: 'bold',
            mr: 1, 
          }}
        >
          |
        </Typography>

        {/* Imagen */}
        <Box
          component="img"
          src={errorImage} 
          alt="Imagen de error"
          sx={{
            width: { xs: '80px', md: '200px' },  
            height: { xs: '80px', md: '200px' },
            objectFit: 'cover',
          }}
        />
      </Box>

      {/* Mensaje descriptivo */}
      <Typography
        variant="h4"
        sx={{
          fontSize: { xs: '20px', md: '30px' },
          fontFamily: 'Futura Light BT',
          color: '#4A4A4A', 
          textAlign: 'center',
          mb: 4,
        }}
      >
        ¡Ups! La página que buscas no existe.
      </Typography>

      <Button
        component={Link}
        to="/"
        variant="contained"
        sx={{
          backgroundColor: '#C7EA46',  
          '&:hover': { backgroundColor: '#A7C536' }, 
          fontFamily: 'Futura Book font',
          px: 4,
          py: 2,
          fontSize: { xs: '14px', md: '18px' },
        }}
      >
        Regresar al Inicio
      </Button>
    </Box>
  );
}

export default Error404;
