import React from 'react';
import AppTerminos from "../../components/navigation/AppTerminos"

function Terminos(){
    return(
        <div>
            <AppTerminos/>    
        </div>
    )
}

export default Terminos